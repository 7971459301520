import React from 'react';

const ChevronDownIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8518 8.50076L19.5 10.1458L12.0065 17.6536L4.5 10.1466L6.14667 8.5L12.005 14.3587L17.8518 8.50076Z"
        fill="#191919"
      />
    </svg>
  );
};

export default ChevronDownIcon;

import React, { useContext, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import '../../styles/common-styles.scss';
import MainLayout from '../../components/MainLayout';
import './styles.scss';
import { MoonLoader } from 'react-spinners';
import classnames from 'classnames';
import { DeviceAuthContext } from '../../providers/DevicesAuthProvider';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { PATH_APP } from '../../routes';
import { devicesConnect, getOfficesStructure } from '../../services/http/requests';
import ChevronRightIcon from '../../icons/ChevronRightIcon';
import ArrowLeftIcon from '../../icons/ArrowLeftIcon';
import ChevronDownIcon from '../../icons/ChevronDownIcon';

const OfficeTreePage = () => {
  const navigate = useNavigate();
  const { tenant } = useParams();
  const { deviceType } = useContext(DeviceAuthContext);
  const [officeStructure, setOfficesStructure] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const isReception = localStorage.getItem('DEVICE_AUTHORISATION::TYPE') === 'reception';

  useEffect(() => {
    try {
      setIsLoading(true);
      getOfficesStructure()
        .then((res) => {
          setOfficesStructure(res);
        })
        .catch((err) => console.error(err))
        .finally(() => setIsLoading(false));
    } catch (err) {
      console.error(err);
    }
  }, []);

  const handleClickOpenList = (e) => {
    const elem = e.currentTarget.nextElementSibling;
    if (elem.classList.contains('active')) {
      elem.classList.remove('active');
      e.currentTarget.classList.remove('active-item');
    } else {
      elem.classList.add('active');
      e.currentTarget.classList.add('active-item');
    }
  };

  const handleSelectBuild = async (build) => {
    setIsLoading(true);
    try {
      const dto = { type: deviceType, zone: null, office: build.office, building: build._id };
      const href = await devicesConnect(dto);
      window.location.replace(href);
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  };

  const handleSelectFloor = (floor) => {
    localStorage.setItem('DEVICE_AUTHORISATION::OFFICE_ID', floor.office);
    localStorage.setItem('DEVICE_AUTHORISATION::BUILDING_ID', floor.building);
    localStorage.setItem('DEVICE_AUTHORISATION::FLOOR_ID', floor._id);
    navigate(PATH_APP.space(tenant));
  };

  const handleBackClick = () => {
    navigate(PATH_APP.type(tenant));
  };

  if (!deviceType) {
    return <Navigate to={PATH_APP.type(tenant)} replace />;
  }

  return (
    <MainLayout>
      <div className={classnames('office-tree', 'wrapper', isLoading ? 'loading' : '')}>
        <h1 className="win-title">
          <ArrowLeftIcon className="win-title__icon" onClick={handleBackClick} />
          {isReception ? <Trans i18nKey="office-tree-title" /> : <Trans i18nKey="floor-tree-title" />}
        </h1>

        <p className="win-description">
          {isReception ? <Trans i18nKey="office-tree-description" /> : <Trans i18nKey="floor-tree-description" />}
        </p>

        {isLoading ? (
          <div className={'office-tree__loader'}>
            <MoonLoader loading={isLoading} />
          </div>
        ) : (
          <ul className={'office-tree__list office-tree__offices '}>
            {officeStructure.map((office) => (
              <li key={office._id} className="office-tree__item">
                <div className="office-tree__button" onClick={handleClickOpenList}>
                  <div className={'icon-open'}>
                    <ChevronDownIcon />
                  </div>
                  <div className={'icon-close'}>
                    <ChevronRightIcon />
                  </div>
                  <p className="office-tree__name">
                    <span>{office?.name || ''}</span>
                  </p>
                </div>
                {isReception ? (
                  <ul className={'office-tree__list office-tree__buildings'}>
                    {office.buildings.map((build) => (
                      <li key={build._id} className="office-tree__item">
                        <div className="office-tree__floor-button" onClick={() => handleSelectBuild(build)}>
                          <p className="office-tree__name">
                            <span>{build?.name || ''}</span>
                          </p>
                          <div>
                            <ChevronRightIcon />
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <ul className={'office-tree__list office-tree__buildings'}>
                    {office.buildings.map((build) => (
                      <li key={build._id} className="office-tree__item">
                        <div className="office-tree__button" onClick={handleClickOpenList}>
                          <div className={'icon-open'}>
                            <ChevronDownIcon />
                          </div>
                          <div className={'icon-close'}>
                            <ChevronRightIcon />
                          </div>
                          <p className="office-tree__name">
                            <span>{build?.name || ''}</span>
                          </p>
                        </div>
                        <ul className={'office-tree__list office-tree__floors'}>
                          {build.floors.map((floor) => (
                            <li key={floor._id} className="office-tree__item">
                              <div className="office-tree__floor-button" onClick={() => handleSelectFloor(floor)}>
                                <p className="office-tree__name">
                                  <span>{`${floor?.title || ''} ${floor?.number || ''}`}</span>
                                </p>
                                <div>
                                  <ChevronRightIcon />
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    </MainLayout>
  );
};

export default OfficeTreePage;
